























































import { Component, Vue } from "vue-property-decorator";
import PoliciesHeader from "./PoliciesHeader.vue";

@Component({ components: { PoliciesHeader } })
export default class GroupCommunicationsPolicy extends Vue {}
